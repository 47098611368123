var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "material-flow" }, [
    _c("div", { staticClass: "goods-list" }, [_c("order-goods")], 1),
    _c(
      "div",
      { staticClass: "material-box" },
      [
        _vm.expName && _vm.number
          ? _c("div", { staticClass: "material-title" }, [
              _vm._v(_vm._s(_vm.expName) + "：" + _vm._s(_vm.number)),
            ])
          : _vm._e(),
        _vm._l(_vm.logistics_list, function (item, index) {
          return _c("div", { key: index, staticClass: "material-line" }, [
            _c("div", { staticClass: "time" }, [
              _c("p", [_vm._v(_vm._s(item.date))]),
              _c("p", [_vm._v(_vm._s(item.minutes))]),
            ]),
            _c("div", { staticClass: "info" }, [
              _c("p", [_vm._v(_vm._s(item.status))]),
            ]),
          ])
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }