<template>
  <div class="material-flow">
    <div class="goods-list">
      <order-goods></order-goods>
    </div>
    <div class="material-box">
      <div class="material-title" v-if="expName&&number">{{expName}}：{{number}}</div>
      <div class="material-line" v-for="(item,index) in logistics_list" :key="index">
        <div class="time">
          <p>{{item.date}}</p>
          <p>{{item.minutes}}</p>
        </div>
        <div class="info">
          <p>{{item.status}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import orderGoods from "@/components/order/orderGoods";
import {getLogisticsInfo} from "@/services/orderApi.js";

export default {
  name: "materialFlow",
  data() {
    return {
      order_id: this.$route.query.order_id,
      logistics_info: '',//物流信息
      logistics_list:'',
      expName:'',//公司
      number:'',//单号
    };
  },
  components: {
    orderGoods
  },
  methods: {
    /**
     * 获取物流信息
     * @returns {Promise<void>}
     */
    async getLogisticsInfo() {
      try {
        const res = await getLogisticsInfo({
					order_id: this.order_id
				});
        if (res.code === 0) {
          const data = res.data;
          if (data.source_type) {
            if (data.orderLogistics.length > 0) {
              this.logistics_status = 0
              this.logistics_list = data.orderLogistics.reverse();
              for (let i = 0; i < this.logistics_list.length; i++) {
                this.$set(this.logistics_list[i], 'date', this.logistics_list[i].time.slice(5, 10))
                this.$set(this.logistics_list[i], 'minutes', this.logistics_list[i].time.slice(11, 16))
              }
            }
          } else if (data.status == 0) {
            this.expName = data.result.expName;
            this.number = data.result.number;
            this.logistics_info = data.result;
            if (data.result.list.length > 0) {
              this.logistics_list = data.result.list;
              for (let i = 0; i < this.logistics_list.length; i++) {
                this.$set(this.logistics_list[i], 'date', this.logistics_list[i].time.slice(5, 10))
                this.$set(this.logistics_list[i], 'minutes', this.logistics_list[i].time.slice(11, 16))
              }
              console.log(this.logistics_list);
            }
          } else {
            this.logistics_info = '暂无物流信息'
          }

        }
      } catch (e) {

      }
    },
  },
  mounted() {
    this.getLogisticsInfo()
  }
};
</script>
<style scoped lang="less">
.material-flow {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0.3rem 0;
  box-sizing: border-box;

  .material-box {
    box-sizing: border-box;
    flex: 1;
    background: #fff;
    padding: 0 0.18rem;
    box-sizing: border-box;

    .material-title {
      height: 1.08rem;
      display: flex;
      align-items: center;
      font-size: 0.26rem;
      color: #333333;
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 0.5rem;
    }

    .material-line {
      margin-bottom: 0.7rem;
      display: flex;
      align-items: center;
      color: #999999;

      .time {
        width: 0.8rem;
        margin-right: 0.66rem;

        p {
          text-align: center;
          white-space: nowrap;
        }

        p:nth-child(1) {
          font-size: 0.28rem;
          line-height: 0.4rem;
        }

        p:nth-child(2) {
          font-size: 0.22rem;
          line-height: 0.32rem;
        }
      }

      .info {
        p:nth-child(1) {
          font-size: 0.28rem;
          line-height: 0.4rem;
        }

        p:nth-child(2) {
          font-size: 0.24rem;
          line-height: 0.34rem;
        }
      }
    }

    .material-line:nth-child(1) {
      color: #333333;
    }
  }
}
</style>
